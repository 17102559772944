<template>
  <main class="text-white" :class="($route.path == '/login') ? 'ml-0' : 'md:ml-64 pb-16 mb-2 md:pb-0 md:mb-0'">
    <!--SideBar-->
    <SideBar :options="menuOptions" :adminOptions="adminOptions" v-if="($route.path != '/login')" />
    <!--View-->
    <router-view />
    <!--NavBar-->
    <NavBar :options="menuOptions" v-if="($route.path != '/login')"/>
  </main>
</template>

<script lang="ts">
//Custom component
import SideBar from './components/SideBar.vue';
import NavBar from './components/NavBar.vue';

//this component
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppView',
  data() {
    return {
      adminOptions: [],
      menuOptions: [
        {
          link: '/banners',
          label: 'Banners',
          icon: '/assets/icons/albums-outline.svg'
        },
        {
          link: '/peliculas',
          label: 'Peliculas',
          icon: '/assets/icons/film-outline.svg'
        },
        /*{
          link: '/candy',
          label: 'Candy',
          icon: '/assets/icons/popcorn.svg'
        },*/
        {
          link: '/ventas-web',
          label: 'Ventas Web',
          icon: '/assets/icons/ticket.svg'
        },
        /*{
          link: '/mercado-pago',
          label: 'Mercado Pago',
          icon: '/assets/icons/card-outline.svg'
        },*/
        {
          link: '/config',
          label: 'Config',
          icon: '/assets/icons/settings.svg',
          subOptions: [
            {
              label: 'Logo',
              link: '/config/logo'
            },
            {
              label: 'Mercado Pago',
              link: '/config/mercado-pago',
            },
          ]
        },
        {
          link: '/login?action=logout',
          label: 'Salir',
          icon: '/assets/icons/logout-circle-r.svg'
        }
      ]
    }
  },
  components: {
    SideBar,
    NavBar
  },
  created() {
    //check if logged in
    if (!this.$store.state.isAuth) {
      this.$router.push('/login')
    }
  }
})
</script>

<style>
* {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}
</style>