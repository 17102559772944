import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NavBar = _resolveComponent("NavBar")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["text-white", (_ctx.$route.path == '/login') ? 'ml-0' : 'md:ml-64 pb-16 mb-2 md:pb-0 md:mb-0'])
  }, [
    ((_ctx.$route.path != '/login'))
      ? (_openBlock(), _createBlock(_component_SideBar, {
          key: 0,
          options: _ctx.menuOptions,
          adminOptions: _ctx.adminOptions
        }, null, 8, ["options", "adminOptions"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    ((_ctx.$route.path != '/login'))
      ? (_openBlock(), _createBlock(_component_NavBar, {
          key: 1,
          options: _ctx.menuOptions
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ], 2))
}