<template>
    <div id="sectionHeader"
        class="sticky top-0 left-0 w-full h-16 md:h-20 px-4 md:px-8 flex items-center justify-between z-10 bg-neutral-950 text-white">
        <div class="start flex items-center gap-4 text-white flex-none">
            <Icon :icon-img="icon" class="w-5 bg-white"></Icon>
            <p class="text-md font-semibold">{{ name }}</p>
        </div>
        <slot></slot>
    </div>
</template>

<script lang="ts">
//Custom component
import Icon from './Icon.vue';

//this component
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SectionHeader',
    components: {
        Icon
    },
    props: {
      icon: String,
      name: String,
    }
})
</script>