<template>
    <div class="banners">
        <!-- section-header -->
        <SectionHeader :icon="sectionIcon" :name="sectionName">
            <!--Info -->
            <div class="end hidden md:flex gap-2 items-center justify-center">
                <p class="text-sm">Tamaño recomendado de banners: 1280 x 448 px</p>
                <Icon class="w-5 bg-white" iconImg="/assets/icons/info.svg"></Icon>
            </div>
        </SectionHeader>

        <!--Section banners-->
        <section class="sectionBanners px-4 pb-4 md:px-8 md:pb-8 pt-0 grid grid-cols-1 gap-4 md:gap-8 md:grid-cols-2" v-if="!isLoading">
            <Uploader v-for="banner, index in banners" :key="index" :data="banner" @deleted="removeBanner($event)"></Uploader>
            <Uploader v-if="banners.length < 6" @uploaded="addBanner($event)"></Uploader>
        </section>

        <!--Skeleton-->
        <section class="bannersSkeleton px-4 pb-4 md:px-8 md:pb-8 pt-0 grid grid-cols-1 gap-4 md:gap-8 md:grid-cols-2" v-else>
            <Uploader v-for="i in 6" :key="i" :skeleton="true"></Uploader>
        </section>
    </div>
</template>

<script lang="ts">
//utilities
import utilities from '@/utilities'

//Custom coomponent
import Uploader from '@/components/Uploader.vue';
import Icon from '@/components/Icon.vue';
import SectionHeader from '@/components/SectionHeader.vue'

//this component
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'BannersView',
    mixins: [utilities],
    components: {
        Uploader,
        Icon,
        SectionHeader
    },
    data(){
        return {
            sectionIcon: "/assets/icons/albums-outline.svg",
            sectionName: "Banners",
            banners: [] as any[any],
            isLoading: true
        }
    },
    async created() {
        //set loading status true
        this.isLoading = true

        //get company id
        let companyId = this.$store.state.user.companyId

        //get banners
        await this.getBanners(companyId)

        //set loading status false
        this.isLoading = false
    },
    methods: {
        async getBanners(companyId: number) {
            //get banners
            this.banners = await this.getItems('/banners/' + companyId).catch(error => {
                console.log(error.response)
                throw 'Error de Servidor'
            })
        },
        addBanner(banner: object){
            //Add banner
            this.banners.push(banner)
        },
        removeBanner(id: number){
            //remove banner
            this.banners.forEach((banner: any, index: number) => {
                if(banner.id == id){
                    this.banners.splice(index, 1)
                }
            })
        }
    }
})
</script>