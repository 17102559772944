<template>
    <div class="navBarContainer w-full p-4 fixed left-0 bottom-0 bg-gradient-to-t from-darkgray to-transparent md:hidden">
        <div class="navBar bg-neutral-900 rounded-full px-4 flex items-center justify-between border border-neutral-800">
            <router-link class="w-full" :to="option.link" v-for="(option, index) in options" :key="index">
                <div class="menu-option flex flex-col text-white gap-2 items-center p-4" :class="(option.link == $route.path) ? 'opacity-100 border-b border-white' : 'opacity-50'">
                    <Icon class="w-5 bg-white" :iconImg="option.icon"/>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script lang="ts">
//Custom component
import Icon from './Icon.vue';

//this component
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NavBar',
    props:{
        options: Array as any[any]
    },
    components: {
        Icon
    }
})
</script>