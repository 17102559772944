<template>
    <div id="sideBar"
        class="fixed top-0 -left-64 md:left-0 h-full w-64 bg-neutral-900 border-r border-neutral-800 flex flex-col text-white ease-linear duration-100 z-20">
        <!-- header -->
        <div class="header w-full h-20 px-6 flex items-center text-white">
            <p class="font-bold">GAF Web Admin</p>
        </div>
        <!-- body -->
        <nav class="overflow-auto flex-1">
            <div id="groupTitle" class="flex items-center px-6 opacity-50">
                <p class="text-sm">General</p>
            </div>
            <ul>
                <li class="cursor-pointer" v-for="(option, index) in options" :key="index" >
                    <router-link :to="option.link" class="menu-option flex items-center gap-4 px-6 py-4 opacity-50 hover:opacity-100" v-if="!option.subOptions">
                        <Icon class="w-5 bg-white" :iconImg="option.icon" />
                        <p>{{ option.label }}</p>
                    </router-link>
                    <Dropdown class="px-6" :label="option.label" :icon="option.icon" :options="option.subOptions" v-else></Dropdown>
                </li>
            </ul>
        </nav>
        <!-- footer-->
        <p class="text-xs opacity-50 p-6">{{ 'Ver. ' + $store.state.appVersion }}</p>
    </div>
</template>

<script lang="ts">
//Custom component
import Icon from './Icon.vue';
import Dropdown from './Dropdown.vue'

//this component
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SideBar',
    components: {
        Icon,
        Dropdown
    },
    props: {
        adminOptions: {
            type: Array as any[any]
        },
        options: {
            type: Array as any[any]
        }
    }
})
</script>