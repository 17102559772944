//imports
import { version } from "../package.json"
import axios from "axios"
import { upload } from "upload"

//this component
import { defineComponent } from "vue"

export default defineComponent({
    name: 'utilitiesTools',
    data() {
        return {
            apiUrl: (process.env.NODE_ENV === 'production')
                ? window.location.protocol + '//apiv2.gaf.adro.studio'
                : window.location.protocol + '//adronas.tplinkdns.com'
                //apiUrl: 'https://apiv2.gaf.adro.studio'
        }
    },
    mounted(){
        //set app version
        this.$store.commit('setAppVersion', version)
    },
    methods: {
        async auth(email: string, password: string, onError: any, continueTo: string) {
            const endpoint = this.apiUrl + '/auth'

            const response = await axios.post(endpoint, { 'email': email, 'password': password })
                .catch(error => {
                    onError(error.response.data.message)
                }) as any

            if (response && response.status == 200) {
                this.$store.commit('setAuth', true)
                this.$store.commit('setUserData', response.data.user)
                this.$store.commit('setUserCinemas', response.data.cinemas)

                if (continueTo) {
                    this.$router.push('/' + continueTo)
                }
                else {
                    this.$router.push('/')
                }
            }
        },
        checkForm(form: FormData) {
            let emptyInputs = true

            Array.from(form).forEach((input: any[any]) => {
                if (input.required && input.value === '' && emptyInputs) {
                    emptyInputs = false
                }
            })

            return emptyInputs
        },
        logOut() {
            this.$store.commit('setAuth', false)
            this.$store.commit('setUserData', [])
            this.$store.commit('setUserCinemas', [])
            this.$store.commit('setSelectedCinema', null)
            this.$router.push('/login')
        },
        async getItems(request: string, token = '', returnTo = '/') {
            const endpoint = this.apiUrl + request

            try {
                const response = await axios.get(endpoint);

                if (response.status == 200) {
                    if (response.data.meta) {
                        return response.data
                    }
                    else {
                        return response.data.data
                    }
                }
            }
            catch (error: any) {
                if (error.response && error.response.status === 401) {
                    this.$router.push('/login?continueTo=' + returnTo);
                }
            }
        },
        async uploadImage(params: object){
            const response = await upload(this.apiUrl + '/file', params as FormData)

            return response
        },
        async deleteImage(id: number){
            //delete banner
            await axios.delete(this.apiUrl + '/file/' + id).catch(error => {
                console.log(error.response)
                throw 'Error de Servidor'
            })
        },
        getMpUrl(companyId: number, cinemaId: number){
            const mpUrl = this.apiUrl + '/mpAuth?id=' + cinemaId + '-' + companyId
            return mpUrl
        }
    }
})