import { createStore } from 'vuex'

//vuex persist
import vuexPersistance from 'vuex-persist';

export default createStore({
  state: {
    appVersion: 0,
    user: {},
    cinemas: [],
    isAuth: false,
    selectedCinema: 0
  },
  mutations: {
    setAppVersion(state, data) {
      state.appVersion = data
    },
    setAuth(state, status) {
      state.isAuth = status
    },
    setUserData(state, data) {
      state.user = data
    },
    setUserCinemas(state, data) {
      state.cinemas = data
    },
    setSelectedCinema(state, id) {
      state.selectedCinema = id
    }
  },
  plugins: [
    new vuexPersistance({
      storage: window.localStorage
    }).plugin
  ]
})
