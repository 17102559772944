import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "navBarContainer w-full p-4 fixed left-0 bottom-0 bg-gradient-to-t from-darkgray to-transparent md:hidden" }
const _hoisted_2 = { class: "navBar bg-neutral-900 rounded-full px-4 flex items-center justify-between border border-neutral-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: "w-full",
          to: option.link,
          key: index
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["menu-option flex flex-col text-white gap-2 items-center p-4", (option.link == _ctx.$route.path) ? 'opacity-100 border-b border-white' : 'opacity-50'])
            }, [
              _createVNode(_component_Icon, {
                class: "w-5 bg-white",
                iconImg: option.icon
              }, null, 8, ["iconImg"])
            ], 2)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ]))
}