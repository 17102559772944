<template>
    <div class="categoryDropdown h-full relative" @mouseenter="(mode == 'floating') ? toggleDropdown() : ''"
        @mouseleave="(mode == 'floating') ? toggleDropdown() : ''">
        <div class="categories h-full flex justify-between gap-2 items-center cursor-pointer opacity-50 hover:opacity-100 py-4"
            @click="(mode != 'floating') ? toggleDropdown() : ''">
            <div class="mainOption flex gap-4">
                <Icon class="w-5 bg-white" :iconImg="icon" v-if="icon" />
                <p v-if="label">{{ label }}</p>
            </div>
            <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 15.496a1 1 0 0 1-.71-.29l-4-4a1.004 1.004 0 0 1 1.42-1.42l3.29 3.31 3.3-3.18a1 1 0 1 1 1.38 1.44l-4 3.86a1 1 0 0 1-.68.28Z">
                </path>
            </svg>
        </div>
        <div :class="(mode == 'floating') ? 'dropdown w-max min-w-full bg-white absolute left-1/2 -translate-x-1/2 border rounded-b-md shadow-md overflow-hidden' : ''"
            v-if="(options as any).length > 0 && showDropdown">
            <router-link :to="(dropdownOption as any).link"
                class="option w-full flex gap-4 py-2 cursor-pointer opacity-50 hover:opacity-100" v-for="dropdownOption, index in options"
                :key="index" @click="(mode == 'floating') ? toggleDropdown() : optionClicked()">
                <p class="w-5 text-center">-</p>
                <p>{{ (dropdownOption as any).label }}</p>
            </router-link>
        </div>
    </div>
</template>

<script lang="ts">
//Components
import Icon from './Icon.vue'

//This component
import { defineComponent } from 'vue'

export default defineComponent({
    name: "DropdownComponent",
    data() {
        return {
            showDropdown: false
        }
    },
    components: {
        Icon
    },
    emits: ['optionClicked'],
    props: {
        mode: String,
        label: String,
        icon: String,
        options: {
            type: Array,
            default: [] as any[any]
        }
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown
        },
        optionClicked() {
            this.toggleDropdown()
            this.$emit('optionClicked')
        },
    }
})
</script>