import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "sectionHeader",
  class: "sticky top-0 left-0 w-full h-16 md:h-20 px-4 md:px-8 flex items-center justify-between z-10 bg-neutral-950 text-white"
}
const _hoisted_2 = { class: "start flex items-center gap-4 text-white flex-none" }
const _hoisted_3 = { class: "text-md font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        "icon-img": _ctx.icon,
        class: "w-5 bg-white"
      }, null, 8, ["icon-img"]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.name), 1)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}