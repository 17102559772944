import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Banners from '../views/Banners.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/banners'
  },
  {
    path: '/banners',
    name: 'Banners',
    component: Banners
  },
  {
    path: '/peliculas',
    name: 'Movies',
    component: function() {
      return import ('../views/Movies.vue')
    }
  },
  {
    path: '/ventas-web',
    name: 'Ventas',
    component: function() {
      return import ('../views/Sales.vue')
    }
  },
  /*{
    path: '/config',
    name: 'Config',
    component: function() {
      return import ('../views/Config.vue')
    }
  },*/
  {
    path: '/config/mercado-pago',
    name: 'Mercado Pago',
    component: function() {
      return import ('../views/PaymentCredentials.vue')
    }
  },
  {
    path: '/config/mercado-pago/:status',
    name: 'Mercado Pago Status',
    component: function() {
      return import ('../views/PaymentCredentials.vue')
    }
  },
  {
    path: '/config/logo',
    name: 'Logo',
    component: function() {
      return import ('../views/LogoConfig.vue')
    }
  },
  {
    path: '/programacion',
    name: 'Programación',
    component: () => import('../views/Showtimes.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
